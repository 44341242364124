import React from 'react';
import bgImage from '../img/iStock-1427610220.jpg';
import mulchImage from '../img/plastic_mulch.jpeg';
import logo from '../img/Service3.png';
import usp1 from '../img/usp1.png';
import usp2 from '../img/usp2.png';
import usp3 from '../img/usp3.png';
import usp4 from '../img/usp4.png';
import advan from '../img/m2.jpg';
import lim from '../img/m1.jpg';
import imp from '../img/m3.jpg';
import method1 from '../img/mul3.jpg';
import method2 from '../img/mul4.jpg';
import method3 from '../img/mul5.jpg';

function PlasticPaper() {
  const why = [
    {
      usp: usp1,
      uspHead: "Crafted with Precision",
      uspText: "At Planet Agro Industries, we craft our products using cutting-edge imported machinery to ensure every item you receive embodies the highest precision and attention to detail. This meticulous approach guarantees exceptional quality in every product we offer."
    },
    {
      usp: usp2,
      uspHead: "Premium Quality Guaranteed",
      uspText: "We take pride in using 100% virgin and UV-stabilized materials in our products. This commitment ensures that our items are not only durable but also resistant to the damaging effects of sunlight, providing a longer lifespan and superior performance."
    },
    {
      usp: usp3,
      uspHead: "Exceptional Durability with Reinforced Edges",
      uspText: "Our products come with reinforced edges, offering added strength that enhances durability and provides a uniform shading effect. This means you can rely on our products to withstand wear and tear, season after season."
    },
    {
      usp: usp4,
      uspHead: "A Splash of Vibrancy",
      uspText: "We understand the importance of aesthetics. That's why we offer an array of attractive colors and varieties. With our diverse range, you can benefit from our high-quality materials while also adding a touch of visual appeal to your space."
    },
  ];
  const cost = {
    blackLLDPEFilm: {
      costOfCultivation: 16000,
      costOfMulchFilm: 16600,
      totalSeasonalCost: 32600,
      yield: 11660,
      marketPrice: 6.00,
      revenue: 69960,
      totalExpenditure: 32600,
      netIncome: 37360,
      benefitCostRatio: '1.45:1'
    },
    control: {
      costOfCultivation: 21000,
      yield: 7770,
      marketPrice: 6.00,
      revenue: 46620,
      totalExpenditure: 21000,
      netIncome: 25620
    },
    differenceInNetSeasonalIncome: 11740
  };

  const data = [
    { treatment: 'T1 – 15 micron LLDPE (black)', moisture: '7.69', weedWeight: '150', pods: '9.00', yield: '1337' },
    { treatment: 'T2 – 20 micron LLDPE (black)', moisture: '7.62', weedWeight: '156', pods: '7.75', yield: '1118' },
    { treatment: 'T3 – 25 micron LLDPE (black)', moisture: '7.05', weedWeight: '179', pods: '7.50', yield: '1275' },
    { treatment: 'T4 – Coir pith 20 t/ha', moisture: '6.50', weedWeight: '257', pods: '6.75', yield: '1012' },
    { treatment: 'T5 Control – no mulch', moisture: '5.90', weedWeight: '370', pods: '6.75', yield: '850' }
  ];
  return (
    <div className='bg-green-50'>
      <div className="bg-cover bg-center h-96 flex items-center justify-center text-[#0081C5]" style={{ backgroundImage: `url(${bgImage})` }}>
        <h1 className="text-4xl font-bold bg-gray-100 bg-opacity-50 rounded-lg p-6 z-10">Plastic Mulch Paper</h1>
      </div>

      <div className="bg-green-700 shadow-lg rounded-lg mx-4 md:mx-auto mt-8 md:mt-0">
        <div className="flex flex-wrap items-center">
          <div className="w-full md:w-1/2 mb-4 md:mb-0 relative">
            <img src={mulchImage} alt="Plastic Mulch" className="w-full h-64 object-cover rounded-lg shadow-lg md:rounded-r-full" />
            <div className="absolute top-4 right-4 md:top-20 md:-right-8 bg-white rounded-full p-2">
              <img src={logo} alt="Logo" className="w-12 h-12" />
            </div>
          </div>

          <div className="w-full md:w-1/2 md:pl-8">
            <h2 className="text-3xl font-bold text-white mb-4 px-8">Introduction to Plastic Mulch Paper</h2>
            <p className="text-lg leading-relaxed text-gray-100 px-8">
              Plastic mulch is a technique of covering the soil to create favorable conditions for plant growth and efficient crop production. Unlike natural mulches, plastic mulches are impermeable to water, reducing soil moisture evaporation and erosion. This is crucial for water conservation, especially in regions with saline water sources.
            </p>
          </div>
        </div>
      </div>

      <div className="container mx-auto mt-8 px-4 md:px-0">
        <div className="flex flex-wrap md:flex-nowrap justify-center">
          <img src={advan} className="w-full md:w-1/2 h-auto mb-8 md:mb-0 md:mr-8 rounded-lg shadow-lg" alt="Advantages of Plastic Mulch Paper" />
          <div className="w-full md:w-1/2 p-4">
            <section className="mb-8">
              <h1 className="text-3xl font-bold mb-4">Advantages of Plastic Mulch Paper</h1>
              <p className="text-lg text-gray-700 leading-relaxed">
                Mulch paper is the process or practice of covering the soil/ground to create more favorable conditions for plant growth, development, and efficient crop production. Plastic mulches are impermeable to water, preventing direct evaporation of moisture from the soil and limiting water losses and soil erosion.
              </p>
              <ul className="list-disc list-inside mt-4 text-gray-700">
                <li>It is completely impermeable to water.</li>
                <li>It prevents the direct evaporation of moisture from the soil and conserves moisture.</li>
                <li>By suppressing evaporation, it prevents the rise of water containing salts.</li>
                <li>It facilitates fertilizer placement and reduces nutrient loss through leaching.</li>
                <li>It provides a barrier to soil pathogens.</li>
                <li>Opaque mulches prevent germination of annual weeds.</li>
                <li>Reflective mulches repel certain insects.</li>
                <li>Mulches maintain a warm temperature for quicker seed germination and strong root growth.</li>
                <li>Synthetic mulches play a role in soil solarization.</li>
              </ul>
            </section>
            <section className="mb-8">
              <h1 className="text-3xl font-bold mb-4">Moisture Conservation</h1>
              <ul className="list-disc list-inside text-gray-700">
                <li>Plastic film with its moisture barrier properties does not allow the soil moisture to escape Water that evaporates from the soil surface under mulch film, condenses on the lower surface of the film and falls back as droplets.</li>
                <li>Thus moisture is preserved for several days and increases the period between two irrigations.</li>
                <li>The irrigation water or rainfall either moves into the soil thru holes on the mulch around the plant area or through the un-mulched area.</li>
              </ul>
            </section>
            <section>
              <h1 className="text-3xl font-bold mb-4">Weed Control</h1>
              <ul className="list-disc list-inside text-gray-700">
                <li>Black plastic film does not allow the sunlight to pass through on to the soil</li>
                <li>Photosynthesis does not take place in the absence of sunlight below black film hence, it arrests weed growth</li>
              </ul>
            </section>
          </div>
        </div>
        <div className="container mx-auto px-4 py-8">
          <div className="flex flex-col md:flex-row items-center justify-center">
            <div className="md:w-1/2 p-4">
              {/* <section className="mb-8">
                <h2 className="text-3xl font-bold mb-4">Limitations of Plastic Mulch Paper</h2>
                <ul className="list-disc list-inside text-gray-700">
                  <li>Costly compared to organic mulches.</li>
                  <li>Potential for 'burning' or 'scorching' of young plants due to high temperatures under black film.</li>
                  <li>Difficulty in applying top-dressed fertilizer.</li>
                  <li>Issues with reptile movement and rodent activities.</li>
                  <li>Increased runoff and environmental pollution concerns.</li>
                  <li>Challenges in machinery movement.</li>
                  <li>Thin mulches can't be reused for more than one season.</li>
                  <li>Weed penetration through thin films.</li>
                  <li>Toxicity concerns for livestock.</li>
                </ul>
              </section> */}

              <section className="mb-8">
                <h2 className="text-3xl font-bold mb-4">Areas of Application</h2>
                <p className="text-lg text-gray-700 leading-relaxed">
                  Mulch paper is mainly employed for:
                </p>
                <ul className="list-disc list-inside text-gray-700">
                  <li>Moisture conservation in rainfed areas</li>
                  <li>Reduction of irrigation frequency and water saving in irrigated areas</li>
                  <li>Soil temperature moderation in greenhouse cultivation</li>
                  <li>Soil solarization for control of soil-borne diseases</li>
                  <li>Reduce the rain impact, prevent soil erosion and maintain soil structure</li>
                  <li>In places where high-value crops only to be cultivated</li>
                </ul>
              </section>

              <section className="mb-8">
                <h2 className="text-3xl font-bold mb-4">Types of Mulch Film</h2>
                <p className="text-lg text-gray-700 leading-relaxed">
                  A wide range of plastic films based on different types of polymers have all been evaluated for mulch paper at various periods in the 1960s. LDPE, HDPE, and flexible PVC have all been used and although there were some technical performance differences between them, they were of minor nature. Owing to its greater permeability to long-wave radiation which can increase the temperature around plants during the night times, polyethylene is preferred. Today the vast majority of plastic mulch is based on LDPE.
                </p>
              </section>
            </div>

            <img src={lim} className="w-full md:w-1/2 h-auto md:ml-8 mb-8 md:mb-0" alt="Limitations of Plastic Mulch Paper" />
          </div>

          <div className="flex flex-col md:flex-row items-center justify-center mt-8">
            <img src={imp} className="w-full md:w-1/2 h-1/4 md:mr-8 mb-8 md:mb-0" alt="Importance of Plastic Mulch Paper" />

            <div className="md:w-1/2 p-4">
              <section className="mb-8">
                <h1 className="text-3xl font-bold mb-4">Importance of Plastic Mulch Paper</h1>
                <p className="text-lg text-gray-700 leading-relaxed">
                  Plastic mulch paper plays a vital role in modern agriculture by enhancing soil health, conserving water, and boosting crop yields. It provides a protective barrier against weeds, retains soil moisture, and helps maintain optimal soil temperature, contributing to healthier and more productive plants. Additionally, it supports sustainable farming practices by reducing the need for chemical herbicides and fertilizers, ultimately leading to more efficient and eco-friendly crop production.
                </p>
              </section>

              {/* <section className="mb-8">
                <h2 className="text-3xl font-bold mb-4">Methods of Plastic Mulching</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div>
                    <img src={method1} alt="Method 1" className="w-full h-32 object-cover mb-4 rounded-lg shadow-lg" />
                    <h3 className="text-xl font-bold mb-2">Row Mulching</h3>
                    <p className="text-lg text-gray-700">A method where mulch is applied in rows, often used for crops like tomatoes and strawberries.</p>
                  </div>
                  <div>
                    <img src={method2} alt="Method 2" className="w-full h-32 object-cover mb-4 rounded-lg shadow-lg" />
                    <h3 className="text-xl font-bold mb-2">Bed Mulching</h3>
                    <p className="text-lg text-gray-700">Involves covering the entire bed where crops are planted, ideal for crops like melons and cucumbers.</p>
                  </div>
                  <div>
                    <img src={method3} alt="Method 3" className="w-full h-32 object-cover mb-4 rounded-lg shadow-lg" />
                    <h3 className="text-xl font-bold mb-2">Flat Mulching</h3>
                    <p className="text-lg text-gray-700">Used for crops like potatoes and peppers, where mulch is laid flat over the soil surface.</p>
                  </div>
                </div>
              </section> */}
            </div>
          </div>
        </div>


        {/* <div className="container mx-auto p-4">
          <h1 className="text-2xl font-bold mb-4 text-center">Cost Economics of Mulch Paper</h1>
          <p className="mb-4">The cost economics of mulch paper is crucial for evaluating its financial viability. Here's a detailed analysis based on a Bhendi crop, incorporating various assumptions and key metrics.</p>
          <h3 className="font-semibold mb-2">Assumptions:</h3>
          <ol className="mb-4 list-decimal list-inside">
            <li><span className="font-semibold">Availability:</span> Power and water sources are available.</li>
            <li><span className="font-semibold">Film Price:</span> The cost of synthetic black LLDPE film is Rs. 120 per Kg.</li>
            <li><span className="font-semibold">Film Longevity:</span> PVC film is assumed to last for two seasons, though it may last longer with proper handling.</li>
          </ol>
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-2 px-4 border-b">Particulars</th>
                <th className="py-2 px-4 border-b">Black LLDPE Film</th>
                <th className="py-2 px-4 border-b">Control</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="py-2 px-4 border-b">Cost of cultivation (Rs./ha)</td>
                <td className="py-2 px-4 border-b">{cost.blackLLDPEFilm.costOfCultivation}</td>
                <td className="py-2 px-4 border-b">{cost.control.costOfCultivation}</td>
              </tr>
              <tr>
                <td className="py-2 px-4 border-b">Cost of mulch film per season</td>
                <td className="py-2 px-4 border-b">{cost.blackLLDPEFilm.costOfMulchFilm}</td>
                <td className="py-2 px-4 border-b">--</td>
              </tr>
              <tr>
                <td className="py-2 px-4 border-b">Total seasonal cost</td>
                <td className="py-2 px-4 border-b">{cost.blackLLDPEFilm.totalSeasonalCost}</td>
                <td className="py-2 px-4 border-b">{cost.control.costOfCultivation}</td>
              </tr>
              <tr>
                <td className="py-2 px-4 border-b">Yield of produce (kg/ha)</td>
                <td className="py-2 px-4 border-b">{cost.blackLLDPEFilm.yield}</td>
                <td className="py-2 px-4 border-b">{cost.control.yield}</td>
              </tr>
              <tr>
                <td className="py-2 px-4 border-b">Market price (Rs./kg)</td>
                <td className="py-2 px-4 border-b">{cost.blackLLDPEFilm.marketPrice}</td>
                <td className="py-2 px-4 border-b">{cost.control.marketPrice}</td>
              </tr>
              <tr>
                <td className="py-2 px-4 border-b">Revenue (Rs./ha)</td>
                <td className="py-2 px-4 border-b">{cost.blackLLDPEFilm.revenue}</td>
                <td className="py-2 px-4 border-b">{cost.control.revenue}</td>
              </tr>
              <tr>
                <td className="py-2 px-4 border-b">Total expenditure</td>
                <td className="py-2 px-4 border-b">{cost.blackLLDPEFilm.totalExpenditure}</td>
                <td className="py-2 px-4 border-b">{cost.control.totalExpenditure}</td>
              </tr>
              <tr>
                <td className="py-2 px-4 border-b">Net income</td>
                <td className="py-2 px-4 border-b">{cost.blackLLDPEFilm.netIncome}</td>
                <td className="py-2 px-4 border-b">{cost.control.netIncome}</td>
              </tr>
              <tr>
                <td className="py-2 px-4 border-b">Difference in net seasonal income</td>
                <td className="py-2 px-4 border-b">{cost.differenceInNetSeasonalIncome}</td>
                <td className="py-2 px-4 border-b">{cost.control.netIncome}</td>
              </tr>
              <tr>
                <td className="py-2 px-4 border-b">Benefit-cost ratio</td>
                <td className="py-2 px-4 border-b">{cost.blackLLDPEFilm.benefitCostRatio}</td>
                <td className="py-2 px-4 border-b">--</td>
              </tr>
            </tbody>
          </table>
        </div> */}

        {/* <div className="container mx-auto p-4">
          <h1 className="text-2xl font-bold mb-4 text-center">Case Study: Effect of Mulch Paper on Groundnut</h1>
          <div className="mb-4">
            <h3 className="text-xl font-semibold mb-2">Experiment Setup:</h3>
            <ul className="list-disc list-inside">
              <li>Conducted at Aliyarnagar Research Station.</li>
              <li>Materials: LLDPE black film of varying thickness (15, 20, and 25 microns), coir pith at 20 t/ha, and a control plot.</li>
              <li>Parameters Monitored: Soil moisture, soil temperature, germination, weed weight, root length, and yield.</li>
              <li>Replication: Four replicates per treatment.</li>
              <li>Plot Size: 2m x 1m.</li>
            </ul>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr className="bg-gray-100">
                  <th className="py-2 px-4 border-b text-sm md:text-base">Treatment</th>
                  <th className="py-2 px-4 border-b text-sm md:text-base">Available Moisture at Harvest (%)</th>
                  <th className="py-2 px-4 border-b text-sm md:text-base">Wet Weed Weight (g/plot at 45th day)</th>
                  <th className="py-2 px-4 border-b text-sm md:text-base">No. of Pods/Plant</th>
                  <th className="py-2 px-4 border-b text-sm md:text-base">Pod Yield (kg/ha)</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr key={index}>
                    <td className="py-2 px-4 border-b text-sm md:text-base">{row.treatment}</td>
                    <td className="py-2 px-4 border-b text-sm md:text-base">{row.moisture}</td>
                    <td className="py-2 px-4 border-b text-sm md:text-base">{row.weedWeight}</td>
                    <td className="py-2 px-4 border-b text-sm md:text-base">{row.pods}</td>
                    <td className="py-2 px-4 border-b text-sm md:text-base">{row.yield}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div> */}

      </div>

      <div className="bg-green-50 py-16">
        <div className="container mx-auto">
          <h2 className="text-4xl font-bold text-center mb-8">Why Choose Us?</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {why.map((item, index) => (
              <div key={index} className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg text-center">
                <img src={item.usp} alt={`USP ${index + 1}`} className="w-16 h-16 mb-4" />
                <h3 className="text-xl font-bold mb-2">{item.uspHead}</h3>
                <p className="text-gray-700">{item.uspText}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlasticPaper;
