import React from 'react'
import { BsGraphUpArrow } from "react-icons/bs";
import { MdHighQuality } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import { MdSupportAgent } from "react-icons/md";
import { FaBullseye } from "react-icons/fa";
import { GiPublicSpeaker } from "react-icons/gi";

function Chose() {
  return (
    <div className="bg-green-50 py-12">
      <div className="max-w-7xl mx-auto px-6">
        <h1 className="text-4xl font-bold text-center text-green-600 mb-8">Why Choose Us</h1>
        <p className="text-center text-lg mb-12 text-gray-700">At Planet Agro Industries, our dedication to transforming agriculture through innovative plastic solutions sets us apart. Here are the key reasons why you should choose us:</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center text-center">
            <BsGraphUpArrow className="text-6xl text-green-600 mb-4" />
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">Expertise and Experience</h2>
            <p className="text-gray-600">With nearly ten years of collective experience in the plastics and polymers industry, our team of techno-commercial professionals brings unparalleled knowledge and expertise to every product we create. Our deep understanding of agricultural needs ensures that our products are designed to deliver maximum benefits to farmers.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center text-center">
            <MdHighQuality className="text-6xl text-green-600 mb-4" />
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">High-Quality Products</h2>
            <p className="text-gray-600">We specialize in manufacturing and distributing premium agricultural plastic products, such as Mulch Film and Warp Knit Fabric. Our commitment to quality means you can trust our products to enhance your agricultural practices, resulting in higher yields and improved livelihoods.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center text-center">
            <FaSearch className="text-6xl text-green-600 mb-4" />
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">Innovation and Research</h2>
            <p className="text-gray-600">At Planet Agro Industries, we are driven by a passion for research, development, and innovation. We continuously strive to develop cutting-edge solutions that address the evolving challenges faced by modern farmers. Our products are not just for today’s needs but are designed to meet the demands of tomorrow.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center text-center">
            <MdSupportAgent className="text-6xl text-green-600 mb-4" />
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">Support for Farmers</h2>
            <p className="text-gray-600">We proudly support the "Sons of Soil," providing them with the tools and resources needed to thrive in today’s agricultural landscape. Our products empower farmers to optimize their operations, leading to increased productivity and sustainable success.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center text-center">
            <FaBullseye className="text-6xl text-green-600 mb-4" />
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">Vision for the Future</h2>
            <p className="text-gray-600">We envision a future where modern farmers, equipped with our innovative products, can enjoy prosperous and fulfilling lives. Our goal is to revolutionize agriculture, ensuring that farmers can maximize their productivity and achieve long-term success.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center text-center">
            <GiPublicSpeaker className="text-6xl text-green-600 mb-4" />
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">Commitment to Sustainability</h2>
            <p className="text-gray-600">Our mission is to be a cornerstone in the agricultural industry, driving progress and development for modern farmers. By providing cutting-edge solutions that support agricultural advancements, we enable farmers to optimize their operations and contribute to a thriving, sustainable agricultural sector.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Chose;
