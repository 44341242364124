import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../img/logo1.png';
import { RiMenuLine, RiCloseLine } from 'react-icons/ri'; 

const Navbar = () => {
    const menuItems = [
        { name: 'Home', path: '/' },
        { name: 'About Us', path: '/about' },
        {
            name: 'Product',
            path: '',
            submenu: [
                { name: 'Warp Knitted Fabric', path: '/warpkint' },
                { name: 'Mulching Paper', path: '/plastickaper' },
                { name: 'Lamination/Food Packaging Films', path: '/lamination' },
                { name: 'Silage Stretch Film', path: '/slagestretchfilm' },
                { name: 'LDPE Films', path: '/ldpefilms' },

            ],
        },
        { name: 'Quality', path: '/quality' },
        { name: 'Business Offers', path: '/businessoffers' },
        { name: 'Contact', path: '/contact' },
    ];

    const [menuOpen, setMenuOpen] = useState(false);
    const [dropdownState, setDropdownState] = useState(menuItems.map(() => false)); 

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const toggleDropdown = (index) => {
        const newDropdownState = [...dropdownState];
        newDropdownState[index] = !dropdownState[index];
        setDropdownState(newDropdownState);
    };
   

    return (
        <nav className=" bg-white  p-2 px-20 md:flex md:items-center md:justify-between">
            <div className="flex justify-between items-center">
                <Link to="/" className="text-2xl font-[Poppins] cursor-pointer">
                    <img className="h-10 inline" src={logo} alt="Logo" />
                </Link>
                <button className="text-3xl cursor-pointer mx-2 md:hidden block" onClick={toggleMenu}>
                    {menuOpen ? <RiCloseLine /> : <RiMenuLine />}
                </button>
            </div>
            <ul className={`md:flex md:items-center md:static absolute bg-white w-full left-0 md:w-auto md:py-0 py-4 md:pl-0 pl-7 transition-all ease-in duration-500 ${menuOpen ? 'z-20 top-[80px]' : 'z-20 top-[-400px]'}`}>
                {menuItems.map((item, index) => (
                    <li key={index} className="mx-4 my-6 md:my-0">
                        {!item.submenu ? (
                            <Link
                                to={item.path}
                                className="text-green-700 hover:text-green-600 transition-colors font-bold font-serif antialiased tracking-wide group"
                            >
                                {item.name}
                                <span className="block border-b-2 border-green-700 transform scale-x-0 transition-transform duration-300 group-hover:scale-x-100 origin-left"></span>
                            </Link>
                        ) : (
                            <>
                                <button
                                    onClick={() => toggleDropdown(index)}
                                    className="text-green-700 hover:text-green-600 focus:outline-none transition-colors font-bold font-serif antialiased tracking-wide relative"
                                >
                                    {item.name}{' '}
                                    <svg
                                        className={`w-4 h-4 inline ${
                                            dropdownState[index] ? 'transform rotate-180' : ''
                                        }`}
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 12a1 1 0 01-.707-.293l-5-5a1 1 0 111.414-1.414L10 9.586l4.293-4.293a1 1 0 111.414 1.414l-5 5A1 1 0 0110 12z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                                {dropdownState[index] && (
                                    <div className="absolute mt-2 w-72 md:96 p-4 bg-white divide-y divide-gray-200 rounded-lg shadow-lg transition-opacity duration-300 ease-in-out z-20">
                                        <ul className="py-2 text-green-700">
                                            {item.submenu.map((subitem, subindex) => (
                                                <li key={subindex}>
                                                    <Link
                                                        to={subitem.path}
                                                        className="block px-4 py-2 text-green-700 font-bold font-serif transition-all ease-in-out"
                                                    >
                                                        {subitem.name}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </>
                        )}
                    </li>
                ))}
                <li className="mx-4 my-6 md:my-0">
                    <Link to='/contact' className="text-green-700 hover:text-green-600 transition-colors font-bold font-serif antialiased tracking-wide group">
                        <button className="bg-cyan-400 text-white font-[Poppins] duration-500 px-6 py-2 mx-4 hover:bg-cyan-500 rounded">
                            Get Inquire
                        </button>
                    </Link>
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;
