import React from 'react';
import { FaTools, FaSun, FaBalanceScale, FaSeedling, FaRecycle } from 'react-icons/fa';
import mulchImage from '../img/tessile-indemagliabile-header.jpg';
import logo from '../img/service1.png';
import bgImage from '../img/agro-shade-net-1000x1000.jpg';
import agri from '../img/s1.jpg';
import constu from '../img/s2.jpg';
import inte from '../img/s5.jpg';
import other1 from '../img/s4.jpg';
import other2 from '../img/s3.jpg';
import other3 from '../img/s6.jpg';

const WarpKnit = () => {
  const cardData = [
    {
      icon: <FaTools className="text-green-500 w-6 h-6" />,
      title: "Durability",
      description: "Warp Knit Fabrics are known for their robustness, making them resilient to wear and tear even in challenging environments.",
    },
    {
      icon: <FaBalanceScale className="text-green-500 w-6 h-6" />,
      title: "Flexibility",
      description: "These fabrics offer flexibility in usage, adapting to various agricultural, construction, and decorative needs without compromising performance.",
    },
    {
      icon: <FaSun className="text-green-500 w-6 h-6" />,
      title: "Environmental Control",
      description: "They excel in controlling environmental factors such as sunlight, temperature, and humidity, crucial for optimizing conditions in agriculture and construction.",
    },
    {
      icon: <FaSeedling className="text-green-500 w-6 h-6" />,
      title: "Versatility",
      description: "From agricultural shading to construction safety and decorative applications, Warp Knit Fabrics serve multiple purposes effectively.",
    },
    {
      icon: <FaRecycle className="text-green-500 w-6 h-6" />,
      title: "Longevity",
      description: "With their durable construction, these fabrics provide long-term solutions, reducing replacement costs and ensuring sustained performance.",
    },
  ];

  return (
    <div>
      <div className="bg-cover bg-center h-96 flex items-center justify-center text-[#0081C5]" style={{ backgroundImage: `url(${bgImage})` }}>
        <h1 className="text-4xl font-bold bg-gray-100 bg-opacity-50 rounded-lg p-6 z-10">Warp Knit Fabrics</h1>
      </div>

      <div className="bg-green-700 shadow-lg rounded-lg mx-4 md:mx-auto mt-8 md:mt-0">
        <div className="flex flex-wrap items-center">
          <div className="w-full md:w-1/2 mb-4 md:mb-0 relative">
            <img src={mulchImage} alt="Plastic Mulch" className="w-full h-64 object-cover rounded-lg shadow-lg md:rounded-r-full" />
            <div className="absolute top-4 right-4 md:top-20 md:-right-8 bg-white rounded-full p-2">
              <img src={logo} alt="Logo" className="w-12 h-12" />
            </div>
          </div>

          <div className="w-full md:w-1/2 md:pl-8">
            <h2 className="text-3xl font-bold text-white mb-4 px-8">Introduction to Warp Knit Fabrics</h2>
            <p className="text-lg leading-relaxed text-gray-100 px-8">
              Warp Knit Fabrics have versatile applications across various sectors due to their durability, flexibility, and ability to control environmental conditions.
            </p>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-4 py-8">
                
      </div>

     <div className="container mx-auto px-4 py-8">
        <div className="my-8">
          <div className="md:flex md:items-center">
            <div className="container mx-auto px-4 py-8">
              <img src={agri} alt="Agricultural Usages" className="mx-auto rounded-lg shadow-lg w-96" />
            </div>
            <div className="w-full md:w-full p-4">
              <section className="p-6">
                <h1 className="text-2xl font-bold mb-4">Agricultural Usages</h1>
                <p className="mb-2 font-semibold">Shade and Sunlight Control:</p>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Used in greenhouses and nurseries to provide shade and control sunlight.</li>
                  <li>Helps create optimal growing conditions by controlling light, temperature, and humidity.</li>
                  <li>Available in various density shades ranging from 25% to 95% to cater to different crop needs.</li>
                  <li>Greenhouses made from steel, wooden, or plastic structures are covered with Warp Knit Fabrics to offer year-round protection from sunlight, heat, cold, and wind, facilitating the growth of off-season crops.</li>
                </ul>
              </section>
            </div>
          </div>
        </div>

        <div className="my-8">
          <div className="md:flex md:items-center">
            <div className="w-full md:w-full p-4">
              <section className="p-6 rounded-lg">
                <h1 className="text-2xl font-bold mb-4">Construction Usages</h1>
                <p className="mb-2 font-semibold">Safety and Protection:</p>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Used at construction sites to prevent accidents caused by falling debris such as bricks, concrete blocks, and pipes.</li>
                  <li>Provides a safety barrier for workers and the public.</li>
                </ul>
                <p className="mb-2 font-semibold">Sunlight Control:</p>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Helps control direct sunlight exposure during plastering and brickwork, ensuring better working conditions and quality of work.</li>
                </ul>
              </section>
            </div>
            <div className="container mx-auto px-4 py-8">
              <img src={constu} alt="Construction Usages" className="mx-auto rounded-lg shadow-lg w-96" />
            </div>
          </div>
        </div>

        {/* <div className="my-8">
          <div className="md:flex md:items-center">
            <div className="container mx-auto px-4 py-8">
              <img src={inte} alt="Interior and Exterior Decoration Usages" className="mx-auto rounded-lg shadow-lg w-96" />
            </div>
            <div className="w-full md:w-full p-4">
              <section className="p-6">
                <h1 className="text-2xl font-bold mb-4">Interior and Exterior Decoration Usages</h1>
                <p className="mb-2 font-semibold">Event Ground Covers:</p>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Used as ground covers for open-air exhibitions, shows, and public performances.</li>
                  <li>Provides shade and protection during daytime public meetings, get-togethers, trade shows, and other large gatherings.</li>
                </ul>
              </section>
            </div>
          </div>
        </div> */}

        {/* <div className="my-8">
          <div className="md:flex md:items-center">
            <div className="w-full md:w-1/2 p-4">
              <section className="p-6">
                <h1 className="text-2xl font-bold mb-4">Other Usages</h1>
                <p className="mb-2 font-semibold">Household Purposes:</p>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Used for fencing, terrace gardens, or backyard covers.</li>
                </ul>
                <p className="mb-2 font-semibold">Tree Guards:</p>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Supported by wooden or bamboo frames, Warp Knit Fabrics can protect young trees.</li>
                </ul>
                <p className="mb-2 font-semibold">Hatcheries and Poultries:</p>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Controls sunlight and heat to create suitable conditions for poultry and hatcheries.</li>
                </ul>
                <p className="mb-2 font-semibold">Vermicomposting:</p>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Used as a filter in vermicomposting setups, aiding in the composting process.</li>
                </ul>
              </section>
            </div>
            <div className="md:w-1/2 p-4 flex justify-around">
              <div className="container mx-auto px-4 py-8">
                <img src={other1} alt="Household Purpose" className="mx-auto rounded-lg shadow-lg w-96" />
              </div>
              <div className="container mx-auto px-4 py-8">
                <img src={other2} alt="Tree Guards" className="mx-auto rounded-lg shadow-lg w-96" />
              </div>
              <div className="container mx-auto px-4 py-8">
                <img src={other3} alt="Hatcheries and Poultries" className="mx-auto rounded-lg shadow-lg w-96" />
              </div>
            </div>
          </div>
        </div> */}
        <div className="my-8">
          <div className="md:flex md:items-center">
            <div className="container mx-auto px-4 py-8">
              <img src={agri} alt="Agricultural Usages" className="mx-auto rounded-lg shadow-lg w-96" />
            </div>
            <div className="w-full md:w-full p-4">
              <section className="p-6">
                <h1 className="text-2xl font-bold mb-4">Agricultural Usages</h1>
                <p className="mb-2 font-semibold">Shade and Sunlight Control:</p>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Used in greenhouses and nurseries to provide shade and control sunlight.</li>
                  <li>Helps create optimal growing conditions by controlling light, temperature, and humidity.</li>
                  <li>Available in various density shades ranging from 25% to 95% to cater to different crop needs.</li>
                  <li>Greenhouses made from steel, wooden, or plastic structures are covered with Warp Knit Fabrics to offer year-round protection from sunlight, heat, cold, and wind, facilitating the growth of off-season crops.</li>
                </ul>
              </section>
            </div>
          </div>
        </div>

        <div className="my-8">
          <div className="md:flex md:items-center">
            <div className="w-full md:w-full p-4">
              <section className="p-6 rounded-lg">
                <h1 className="text-2xl font-bold mb-4">Construction Usages</h1>
                <p className="mb-2 font-semibold">Safety and Protection:</p>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Used at construction sites to prevent accidents caused by falling debris such as bricks, concrete blocks, and pipes.</li>
                  <li>Provides a safety barrier for workers and the public.</li>
                </ul>
                <p className="mb-2 font-semibold">Sunlight Control:</p>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Helps control direct sunlight exposure during plastering and brickwork, ensuring better working conditions and quality of work.</li>
                </ul>
              </section>
            </div>
            <div className="container mx-auto px-4 py-8">
              <img src={constu} alt="Construction Usages" className="mx-auto rounded-lg shadow-lg w-96" />
            </div>
          </div>
        </div>
        <div className="my-8">
          <div className="md:flex md:items-center">
            <div className="container mx-auto px-4 py-8">
              <img src={agri} alt="Agricultural Usages" className="mx-auto rounded-lg shadow-lg w-96" />
            </div>
            <div className="w-full md:w-full p-4">
              <section className="p-6">
                <h1 className="text-2xl font-bold mb-4">Agricultural Usages</h1>
                <p className="mb-2 font-semibold">Shade and Sunlight Control:</p>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Used in greenhouses and nurseries to provide shade and control sunlight.</li>
                  <li>Helps create optimal growing conditions by controlling light, temperature, and humidity.</li>
                  <li>Available in various density shades ranging from 25% to 95% to cater to different crop needs.</li>
                  <li>Greenhouses made from steel, wooden, or plastic structures are covered with Warp Knit Fabrics to offer year-round protection from sunlight, heat, cold, and wind, facilitating the growth of off-season crops.</li>
                </ul>
              </section>
            </div>
          </div>
        </div>

        <div className="my-8">
          <div className="md:flex md:items-center">
            <div className="w-full md:w-full p-4">
              <section className="p-6 rounded-lg">
                <h1 className="text-2xl font-bold mb-4">Construction Usages</h1>
                <p className="mb-2 font-semibold">Safety and Protection:</p>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Used at construction sites to prevent accidents caused by falling debris such as bricks, concrete blocks, and pipes.</li>
                  <li>Provides a safety barrier for workers and the public.</li>
                </ul>
                <p className="mb-2 font-semibold">Sunlight Control:</p>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Helps control direct sunlight exposure during plastering and brickwork, ensuring better working conditions and quality of work.</li>
                </ul>
              </section>
            </div>
            <div className="container mx-auto px-4 py-8">
              <img src={constu} alt="Construction Usages" className="mx-auto rounded-lg shadow-lg w-96" />
            </div>
          </div>
        </div>
        <div className="my-8">
          <div className="md:flex md:items-center">
            <div className="container mx-auto px-4 py-8">
              <img src={agri} alt="Agricultural Usages" className="mx-auto rounded-lg shadow-lg w-96" />
            </div>
            <div className="w-full md:w-full p-4">
              <section className="p-6">
                <h1 className="text-2xl font-bold mb-4">Agricultural Usages</h1>
                <p className="mb-2 font-semibold">Shade and Sunlight Control:</p>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Used in greenhouses and nurseries to provide shade and control sunlight.</li>
                  <li>Helps create optimal growing conditions by controlling light, temperature, and humidity.</li>
                  <li>Available in various density shades ranging from 25% to 95% to cater to different crop needs.</li>
                  <li>Greenhouses made from steel, wooden, or plastic structures are covered with Warp Knit Fabrics to offer year-round protection from sunlight, heat, cold, and wind, facilitating the growth of off-season crops.</li>
                </ul>
              </section>
            </div>
          </div>
        </div>

        <div className="my-8">
          <div className="md:flex md:items-center">
            <div className="w-full md:w-full p-4">
              <section className="p-6 rounded-lg">
                <h1 className="text-2xl font-bold mb-4">Construction Usages</h1>
                <p className="mb-2 font-semibold">Safety and Protection:</p>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Used at construction sites to prevent accidents caused by falling debris such as bricks, concrete blocks, and pipes.</li>
                  <li>Provides a safety barrier for workers and the public.</li>
                </ul>
                <p className="mb-2 font-semibold">Sunlight Control:</p>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Helps control direct sunlight exposure during plastering and brickwork, ensuring better working conditions and quality of work.</li>
                </ul>
              </section>
            </div>
            <div className="container mx-auto px-4 py-8">
              <img src={constu} alt="Construction Usages" className="mx-auto rounded-lg shadow-lg w-96" />
            </div>
          </div>
        </div>
        <div className="my-8">
          <div className="md:flex md:items-center">
            <div className="container mx-auto px-4 py-8">
              <img src={agri} alt="Agricultural Usages" className="mx-auto rounded-lg shadow-lg w-96" />
            </div>
            <div className="w-full md:w-full p-4">
              <section className="p-6">
                <h1 className="text-2xl font-bold mb-4">Agricultural Usages</h1>
                <p className="mb-2 font-semibold">Shade and Sunlight Control:</p>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Used in greenhouses and nurseries to provide shade and control sunlight.</li>
                  <li>Helps create optimal growing conditions by controlling light, temperature, and humidity.</li>
                  <li>Available in various density shades ranging from 25% to 95% to cater to different crop needs.</li>
                  <li>Greenhouses made from steel, wooden, or plastic structures are covered with Warp Knit Fabrics to offer year-round protection from sunlight, heat, cold, and wind, facilitating the growth of off-season crops.</li>
                </ul>
              </section>
            </div>
          </div>
        </div>

        <div className="my-8">
          <div className="md:flex md:items-center">
            <div className="w-full md:w-full p-4">
              <section className="p-6 rounded-lg">
                <h1 className="text-2xl font-bold mb-4">Construction Usages</h1>
                <p className="mb-2 font-semibold">Safety and Protection:</p>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Used at construction sites to prevent accidents caused by falling debris such as bricks, concrete blocks, and pipes.</li>
                  <li>Provides a safety barrier for workers and the public.</li>
                </ul>
                <p className="mb-2 font-semibold">Sunlight Control:</p>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Helps control direct sunlight exposure during plastering and brickwork, ensuring better working conditions and quality of work.</li>
                </ul>
              </section>
            </div>
            <div className="container mx-auto px-4 py-8">
              <img src={constu} alt="Construction Usages" className="mx-auto rounded-lg shadow-lg w-96" />
            </div>
          </div>
        </div>

        <div className="my-8">
          <h1 className="text-3xl font-bold mb-4 text-center">Advantages of Choosing Warp Knit Fabrics at Planet Agro Industries</h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {cardData.map((card, index) => (
              <div className=" mx-auto bg-white rounded-lg overflow-hidden shadow-lg border-green-100 border-2" key={index}>
                <div className="p-6">
                  <div className="flex items-center mb-4">
                    {card.icon}
                    <span className="ml-2 font-bold text-xl">{card.title}</span>
                  </div>
                  <p className="text-gray-700 text-base">{card.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> 
    </div>
  );
};

export default WarpKnit;
