import React from "react";
import aboutus1 from "../img/aboutdron.jpeg";
import aboutus2 from "../img/arrange-white-letters-idea.jpg";
import bgimage from "../img/service-bg.jpg";
import bgabout from "../img/plastic_mulch.jpeg";

// Reusable Section Component
function Section({ title, children }) {
  return (
    <div className="mb-12 animate-fade-in">
      <h2 className="text-3xl font-semibold text-green-600 mb-4">{title}</h2>
      <div className="text-lg text-gray-800 leading-relaxed">{children}</div>
    </div>
  );
}

function About() {
  return (
    <div
      className="bg-green-50 px-5 sm:px-10 lg:px-20"
      style={{
        backgroundImage: `url(${bgimage})`,
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
      }}
    >
      {/* Header Section */}
      <div
        className="h-48 flex items-center justify-center bg-cover bg-center relative animate-zoom-in"
        style={{ backgroundImage: `url(${bgabout})` }}
      >
        <div className="absolute inset-0 bg-green-900 bg-opacity-40"></div>
        <h1 className="text-5xl font-bold text-white z-10 animate-slide-down">
          About Us
        </h1>
      </div>

      {/* About Planet Agro Industries */}
      <div className="space-y-8 py-16">
        <h1 className="text-4xl font-bold text-center text-green-600 animate-slide-right">
          About Planet Agro Industries
        </h1>
        <p className="text-xl text-center text-gray-700 mb-10 animate-fade-in">
          Cultivating Innovation in Agriculture
        </p>
        <hr className="border-t-2 w-24 mx-auto border-green-500 mb-10 animate-pulse" />
        <p className="text-lg text-gray-800 leading-relaxed animate-fade-in">
          Planet Agro Industries is one of India’s leading manufacturers of HDPE
          Warp Knitted Fabric with Technical Fibres and Polyethylene Mulch Film,
          Established in year 2017. The company today is known for providing
          high class innovative fabrics as per requirements of todays world.
          Company also manufactures Premium Quality Mulching Paper to meet
          todays Modern agricultural needs to grow plants with high yield with
          proper weed control.
        </p>
        <p className="text-lg text-gray-800 leading-relaxed animate-fade-in">
          Planet Agro Industries is equipped with the latest technology in Yarn
          Extrusion, Multi-Layer Blown Film, Warp Knitting and advanced Fabric
          Packaging Systems.
        </p>
        <p className="text-lg text-gray-800 leading-relaxed animate-fade-in">
          Leading By MR. Sanjay B. Dandekar (MD) and Mr. Tekchand M. Asiwal
          (MD), the company has a clear vision of creating value for its clients
          through innovation, consistent quality, on-time deliveries and
          transparent communication. Planet agro Industries is a Family which
          together with its employees shares a common vision.
        </p>
      </div>

      {/* Image and Text Sections */}
      <div className="mt-10 flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 lg:space-x-12">
        <img
          src={aboutus1}
          className="w-full lg:w-1/2 rounded-lg shadow-lg animate-slide-left"
          alt="Innovation and Research"
          loading="lazy"
        />
        <div>
          <Section title="Innovation and Research">
            At Planet Agro Industries, we focus on research, development, and
            innovation. We design products not just for today but for the future
            of agriculture.
          </Section>
          <Section title="Expertise and Experience">
            With a decade of expertise in plastics and polymers, our team
            creates products tailored for farmers, ensuring high efficiency and
            sustainability.
          </Section>
        </div>
      </div>

      <div className="mt-16 flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 lg:space-x-12">
        <div>
          <Section title="Our Vision">
            <ul className="list-disc list-inside text-lg text-gray-800 leading-relaxed">
              <li>
                We envision a future where modern farmers, equipped with our
                innovative products, can enjoy prosperous and fulfilling lives.
                Our goal is to revolutionize agriculture, ensuring that farmers
                can maximize their productivity and achieve long-term success.
              </li>
              <li>
                To be trusted and recognized for quality and innovative
                solutions.
              </li>
              <li>To understand and meet customer’s requirements.</li>
              <li>To maintain excellence in all our endeavours.</li>
              <li>
                To provide safe and healthy working environments for all the
                employees.
              </li>
            </ul>
          </Section>
          <Section title="Our Mission and Goals">
            <ul className="list-disc list-inside text-lg text-gray-800 leading-relaxed">
              <li>
                To develop credible, consumer centric products that help in
                protecting people, plants and surroundings from harmful UV rays
                and extreme elements of nature.
              </li>
              <li>
                To Improve the efficiency and sustainability of agricultural
                yields through enhanced protection and water conservation.
              </li>
              <li>
                To enhance the aesthetics of open spaces and house hold looking
                for shading, privacy or protection from outdoor elements.
              </li>
              <li>
                To develop stable and long-lasting business and generate
                awareness in the Indian market towards quality shading products.
              </li>
              <li>
                To develop a sales platform for helping customers looking for
                customised shading products.
              </li>
            </ul>
          </Section>
        </div>
        <img
          src={aboutus2}
          className="w-full lg:w-1/2 rounded-lg shadow-lg  animate-slide-right"
          alt="Our Vision and Mission"
          loading="lazy"
        />
      </div>
    </div>
  );
}

export default About;
