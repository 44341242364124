import React from 'react';
import silageImage from '../img/silage-picture-tilak.jpg';
import logo from '../img/Service3.png';
import bgImage from '../img/SML-bg-image.jpeg';
import mulchImage from '../img/Silage-Stretch-Film-Roll-transformed.jpeg';
import { FiCheckCircle, FiZap, FiShield, FiDroplet, FiLayers, FiRecycle, FiGlobe } from 'react-icons/fi';

const SilageStretchFilm = () => {
  return (
    <div>
      <div className="bg-cover bg-green-50 bg-center h-96 flex items-center justify-center text-[#0081C5]" style={{ backgroundImage: `url(${bgImage})` }}>
        <h1 className="text-4xl font-bold bg-gray-100 bg-opacity-50 rounded-lg p-6 z-10">Silage Stretch Film</h1>
      </div>

      <div className="bg-green-700 shadow-lg rounded-lg mx-4 md:mx-auto mt-8 md:mt-0">
        <div className="flex flex-wrap items-center">
          <div className="w-full md:w-1/2 mb-4 md:mb-0 relative">
            <img src={mulchImage} alt="Silage Stretch Film" className="w-full h-64 object-cover rounded-lg shadow-lg md:rounded-r-full" />
            <div className="absolute top-4 right-4 md:top-20 md:-right-8 bg-white rounded-full p-2">
              <img src={logo} alt="Logo" className="w-12 h-12" />
            </div>
          </div>

          <div className="w-full md:w-1/2 md:pl-8">
            <h2 className="text-3xl font-bold text-white mb-4 px-8">Introduction to Silage Stretch Film</h2>
            <p className="text-lg leading-relaxed text-gray-100 px-8">
              Silage stretch film is a type of agricultural plastic film primarily used in the farming industry, especially for wrapping hay, silage, and other forage crops. It plays a crucial role in preserving the quality and nutritional value of harvested crops by creating an airtight seal that protects against moisture and oxygen.
            </p>
          </div>
        </div>
      </div>

      <div className="container mx-auto mt-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div>
            <img src={silageImage} alt="Silage Stretch Film" className="rounded-lg shadow-md" />
          </div>
          <div>
            <h2 className="text-3xl font-bold mb-4">Features</h2>
            <p className="text-lg mb-4">
              Our Silage Stretch Film boasts excellent cling and very high puncture resistance, reducing spoilage and maintaining low permeability to water and oxygen. It also offers high load retention, preserving bale shape throughout its use.
            </p>
            <h3 className="text-xl font-semibold mb-2">Manufacturing Process</h3>
            <p className="mb-4">
              Silage Stretch Films are crucial for preserving silage, essential for livestock feed. At Planet Agro Industries, we specialize in manufacturing high-quality films using advanced processes and materials.
            </p>
            <p className="mb-4">
              The manufacturing involves blending and extruding multiple layers of specialized polyethylene resins, chosen for strength, flexibility, and durability. Our state-of-the-art machinery ensures precise thickness and consistent quality.
            </p>
            <p className="mb-4">
              Planet Agro Industries is a leading global supplier known for superior mechanical properties, UV stabilization, and low oxygen/water permeability in our Silage Stretch Films.
            </p>
            <h3 className="text-xl font-semibold mb-2 text-blue-700">Features and Specifications</h3>
            <ul className="list-disc pl-6 mb-4">
              <li className="flex items-center mb-2">
                <FiCheckCircle className="text-green-500 mr-2" /> Excellent Mechanical Properties: Tensile Strength and Puncture Resistance
              </li>
              <li className="flex items-center mb-2">
                <FiZap className="text-yellow-500 mr-2" /> High Tackiness
              </li>
              <li className="flex items-center mb-2">
                <FiShield className="text-purple-500 mr-2" /> UV Stabilization
              </li>
              <li className="flex items-center mb-2">
                <FiDroplet className="text-blue-500 mr-2" /> Low Oxygen and Water Permeability
              </li>
            </ul>
            <h3 className="text-xl font-semibold mb-2">Available Sizes</h3>
            <p className="mb-4">
              <strong>Width:</strong> 250mm, 500mm, 750mm<br />
              <strong>Thickness:</strong> 23 micron, 29 micron<br />
              <strong>Colors:</strong> White, Green, Black (Customizable)
            </p>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-4 text-center">Benefits and Advantages of Silage Stretch Film</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="bg-gray-100 rounded-lg shadow-lg p-6">
            <div className="flex items-center mb-4">
              <FiCheckCircle className="text-green-500 w-6 h-6" />
              <span className="ml-2 font-bold text-xl">Enhanced Shelf Life</span>
            </div>
            <p className="text-gray-700">
              Our films provide an effective barrier against moisture, oxygen, and other external factors, significantly extending the shelf life of food products.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6">
            <div className="flex items-center mb-4">
              <FiShield className="text-green-500 w-6 h-6" />
              <span className="ml-2 font-bold text-xl">Product Safety</span>
            </div>
            <p className="text-gray-700">
              The protective layer of our silage stretch films ensures that food products remain uncontaminated and safe for consumption.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6">
            <div className="flex items-center mb-4">
              <FiZap className="text-green-500 w-6 h-6" />
              <span className="ml-2 font-bold text-xl">Visual Appeal</span>
            </div>
            <p className="text-gray-700">
              Our films offer excellent clarity and gloss, enhancing the visual appeal of packaged foods and making them more attractive to consumers.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6">
            <div className="flex items-center mb-4">
              <FiLayers className="text-green-500 w-6 h-6" />
              <span className="ml-2 font-bold text-xl">Convenience</span>
            </div>
            <p className="text-gray-700">
              Silage stretch films are easy to handle and can be seamlessly integrated into existing packaging lines, improving efficiency and reducing operational costs.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6">
            <div className="flex items-center mb-4">
              <FiShield className="text-green-500 w-6 h-6" />
              <span className="ml-2 font-bold text-xl">Sustainability</span>
            </div>
            <p className="text-gray-700">
              Our silage stretch films are designed with sustainability in mind, offering recyclable options to reduce environmental impact.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6">
            <div className="flex items-center mb-4">
              <FiGlobe className="text-green-500 w-6 h-6" />
              <span className="ml-2 font-bold text-xl">Versatility</span>
            </div>
            <p className="text-gray-700">
              Suitable for a wide range of food products, our films provide consistent performance across different packaging applications.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SilageStretchFilm;
