import React from 'react'
import { FiPhoneCall } from "react-icons/fi";
import { TfiEmail } from "react-icons/tfi";
import { SlLocationPin } from "react-icons/sl";
import bgimage from '../img/counter2.jpg';

function Contact() {
  return (
    <div className='bg-gray-100'>
      <div className="bg-cover bg-center h-64 flex items-center justify-center" style={{ backgroundImage: `url(${bgimage})` }}>
        <h1 className="text-4xl font-bold text-white">Contact Us</h1>
      </div>
      <div className="container w-full mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
          <div className="text-center bg-white p-6 shadow-lg rounded-lg">
            <div className="flex justify-center items-center mb-4 text-blue-500">
              <FiPhoneCall size={40}/>
            </div>
            <h2 className="text-xl font-semibold mb-2">Call Us</h2>
            <p className="text-lg">+91 93077 28959</p>
            <p className="text-lg">+91 98225 33386</p>
          </div>
          <div className="text-center bg-white p-6 shadow-lg rounded-lg">
            <div className="flex justify-center items-center mb-4 text-blue-500">
              <TfiEmail size={40}/>
            </div>
            <h2 className="text-xl font-semibold mb-2">Write to Us</h2>
            <p className="text-lg">planetagro17@gmail.com</p>
          </div>
          <div className="text-center bg-white p-6 shadow-lg rounded-lg">
            <div className="flex justify-center items-center mb-4 text-blue-500">
              <SlLocationPin size={40}/>
            </div>
            <h2 className="text-xl font-semibold mb-2">Address</h2>
            <p className="text-lg">Gat No. 273, Near Jolly Board, Miraj-Sangola-Pandharpur road, Village- Deshing, Taluka- Kavathemahankal, District- Sangli, Kavathe-Mahankal, India, Maharashtra 416410</p>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div>
            <h2 className="text-2xl font-bold mb-6">Get In Touch</h2>
            <form className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <input type="text" placeholder="Full Name" className="w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 px-4 py-2"/>
                <input type="email" placeholder="Email" className="w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 px-4 py-2"/>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <input type="tel" placeholder="Mobile No." className="w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 px-4 py-2"/>
                <input type="text" placeholder="City" className="w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 px-4 py-2"/>
              </div>
              <textarea placeholder="Your Requirement/Message" className="w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 px-4 py-2"></textarea>
              <button type="submit" className="w-full bg-blue-500 text-white font-bold py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300">Enquire Now</button>
            </form>
          </div>
          <div className="h-96">
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3815.6479660590862!2d74.77574376938007!3d16.991857859891596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc131bde2f67dc7%3A0x99068d3e25e166e9!2sPlanet%20Agro%20Industries!5e0!3m2!1sen!2sin!4v1734594423517!5m2!1sen!2sin"
              className="w-full h-full border-0 rounded-md shadow-lg"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
